footer {
    background-color: lightblue;
    height: 60px;
    display: flex;
    margin-top: auto;
    padding: 20px;
}


.list-div a {
    margin-left: 10px;
}

.list-div a{
    text-decoration: none;
    color: black;
    font-family: 'Helvetica';
    font-size: 18px;
}

.footer-container {
    display: flex;
}

.footer-container p {
    margin: 5px;
}

.envelope-logo, .linkedin-logo, .github-logo {
    width: 30px;
    height: 30px;
}

div.list-div {
    margin-left: auto;
}

footer ul li a:hover {
    text-decoration: underline;
}

p.copy {
    text-align: center;
}

@media (max-width: 680px) {
    footer {
        height: 100px;
      
    }

    .envelope-logo, .linkedin-logo, .github-logo {
        height: 40px;
        width: 40px;

    }

    .footer-container {
        display: block;
    }

    p.separator {
        display: none;
    }

    p.copy {
        text-align: left;
    }

    .list-div {
        display: flex;
        justify-content: center;
        align-items: center;

    }
}