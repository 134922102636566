h1 {
    text-align: center;
    margin: 10px ! important;
}

div.project-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
}