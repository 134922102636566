
.about-container {
    display: flex;
    padding: 0;
}

.about-container div {
    flex-grow: 1;
}

section {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Make section at least full height of viewport */
}

img.self-portrait {
    height: 240px;
    width: 180px;
    border-radius: 15px;
    display: block;
    margin: 5px;
    float: right;
}

h1.title {
    margin: 10px;
    font-size: 25px;
}

p {
    font-size: 18px;
    font-family: 'Roboto', 'Lato', 'Helvetica';
    color: black;
}

.school-experience {
    margin: 10px;
}

h1.lab-heading {
    margin-left: 10px;
    margin-bottom: 0px;
    margin-top: 0px;
}

p.lab-experience {
    margin: 10px;
}



