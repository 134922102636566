.project-card {
    width: 250px;
    height: 300px;
    background-color: lightblue;
    margin: 10px;
    border-radius: 10px;
    overflow: hidden;
}


.project-card img {
    width: 100%;
    height: 50%;
    display: block;
    margin: auto;
}

h2.project-title {
    margin-left: 5px;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 5px;
    font-family: 'Segoe UI';
}

p.project-caption {
    margin-bottom: 0;
    font-size: 18px;
    margin-left: 5px;
    font-family: 'Segoe UI';
}

p.project-description {
    margin-left: 5px;
    margin-bottom: 10px;
    font-size: 18px;
    font-family: 'Segoe UI';
}

.project-card {
    transition: box-shadow 0.3s ease;
  }

  /*
.project-card:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
} */

.project-card button {
    margin-left: 5px;
}

.gh-logo {
    width: 35px;
    height: 35px;
    margin-left: 15px;
    color: black;
}

a.visit {
    color: black;
    margin-left: 10px;
    text-decoration: none;
}

a.visit:hover {
    text-decoration: underline;
}

@media (max-width: 680px) {
    .project-card {
        width: 335px;
        height: 285px;
        margin: 5px;
    }
    /*
    .project-card:hover {
        box-shadow: 0 0 0;
    } */

    h2.project-title {
   
        font-weight: bold;
        font-family: 'Segoe UI';
    }

    p.project-caption {
        font-family: 'Segoe UI';
      
    }

    p.project-description {
        font-family: 'Segoe UI';
        font-size: 18px;
    }
}
