h1.intro-title {
    font-size: 35px;
    margin: 35px;
}

.intro-text p {
    margin: 35px;
    font-size: 18px;
}

div.intro-container {
    display: flex;

}

img.Travon-portrait-home {
    height: 350px;
    width: 300px;
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    border-radius: 15px;
}

img.intro-text-img {
    display: none;
}
  
.intro-text, .intro-pic {
    flex-basis: 50%;
    flex-grow: 1;
}

h1.projects {
    font-size: 25px;
    margin: 20px;
}


.java-logo, .python-logo, .sql-logo, .html-logo, .css-logo, .js-logo, .node-logo, .express-logo, .react-logo, .mongo-logo {
    height: 55px;
    width: 55px;
    margin: 2px;
}

.react-logo {
    height: 55px;
    width: 60px;
}


div.projects-container {
    display: flex;
    margin-bottom: 10px;
    margin-left: 15px;
  
}


div.skills {
    margin-left: 20px;
 }
 
div.skills-row-1, div.skills-row-2 {
    display: inline-block;
}

div.skills2 {
    display: none;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

@media(max-width: 760px) {
    div.skills {
       display: none;
    }

    div.skills2 {
        display: flex;
     }

     div.projects-container {
        align-items: center;
        justify-content: center;
        margin-left: 0px;
    }

    .skills {
        align-self: center;
        justify-content: center;
        display: flex;
    }
}

@media (max-width: 680px) {
    div.intro-container {
        display: block;
    }

    .intro-text p {
        font-size: 16px;
    }

    h1.intro-title {
        margin: 35px;
        font-size: 25px;
    }
    /* 
    div.skills {
        display: block;
    } */

    div.skills-row-1, div.skills-row-2 {
        display: block;
        justify-self: center;
    }
    img.intro-text-img {
        height: 300px;
        width: 250px;
        display: block;
        margin: 0 auto;
        margin-top: 20px;
        border-radius: 15px;
    }

    img.Travon-portrait-home {
      display: none;
    }
    .intro-title {
        text-align: center
    }

    /*div.skills {
       display: flex;
       justify-content: center;
       text-align: center;
    } */

    .java-logo, .python-logo, .sql-logo, .html-logo, .css-logo, .js-logo, .node-logo, .express-logo, .mongo-logo {
        height: 55px;
        width: 55px;
    }

   .react-logo {
        height: 55px;
        width: 60px;
   }
   .projects-container {
    flex-wrap: wrap;
    justify-content: center;
}
}

@media (max-width: 594px) {
    div.skills2 {
        display: block;
        margin: auto;
    }
}





   


