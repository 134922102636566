.navbar {
    background-color: lightblue;
    height: 50px;
    padding: 8px;
    display: flex;
}

.navbar ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
    margin-right: 5px;
}

.navbar ul li {
    margin-left: 10px;
    margin: 5px;
}

.navbar ul li a {
    text-decoration: none;
    color: black;
    font-family: 'Helvetica';
    font-size: 18px;
}

.navbar ul li a:hover {
    text-decoration: underline;
}

.navbar ul li a.active {
    text-decoration: underline;
}

.navbar .nav-title {
    margin-left: 10px;
    white-space: nowrap;
    margin-bottom: 5px;
}

.nav-title {
    font-size: 25px;
    text-decoration: none;
    font-weight: bold;
    color: black;
}

ul.nav-list {
    margin-left: auto;
    display: flex;
    align-items: center;
} 


.hamburger {
    display: none;
}

a.resume-bottom {
    height: 200px;
}
/* height and width of 26px will preserve margins of nav-title */
@media (max-width: 650px) {
    .hamburger {
        display: inline-block;
        width: 30px; 
        height: 30px;
        margin: 5px;
        color: black;
        margin-right: 5px;
    }

     ul.nav-list {
        display: none;
    }

    .nav-title {
        margin-bottom: 5px;
        text-align: center;
    }
  
    ul.nav-open {
        position: absolute;
        display: block;
        background-color: lightblue;
        top: 50px;
        width: 100%; 
        /* or height: 100vh but it doesn't span to 
        the bottom when you scroll*/
    }


    ul.nav-open li {
        border-bottom: 0.5px solid gray ;
    }

    ul.nav-open li:first-child{
        border-top: 0.5px solid gray;
        padding-top: 5px;
    }

    ul.nav-open li {
        text-align: center;
    }

    ul.nav-open :nth-child(4) {
        border-bottom: 0px;
    }

    .hamburger-open {
         color: white;
    }
 }